<template>
  <div class="home-container">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleTabClick(activeTab)"
      class="middle"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.label"
        :label="item.label"
        :name="item.name"
      >
        <div class="article-list">
          <div v-if="articleList.length">
            <template v-for="(item, index) in articleList">
              <baseCard v-bind="item" @refreshData="queryData" :key="index" />
            </template>
          </div>
          <div v-else class="no-result">No result</div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="customer-info">
      <customerInfo />
    </div>
  </div>
</template>

<script>
import baseCard from "@/components/baseCard.vue";
import customerInfo from "@/components/customerInfo.vue";
import { reviewArticle, getAllArticles } from "../api/index";

export default {
  name: "home",
  components: {
    baseCard,
    customerInfo,
  },
  data() {
    return {
      articleList: [],
      activeTab: "Pending Review",
      tabs: [
        {
          label: "Pending Review",
          name: "Pending Review",
        },
        {
          label: "Rejected",
          name: "Rejected",
        },
        {
          label: "Published",
          name: "Published",
        },
      ],
    };
  },
  methods: {
    queryData(status) {
      getAllArticles(status).then((res) => {
        this.articleList = res.data.data || [];
      });
    },
    handleTabClick(tab) {
      this.articleList = [];
      const _status =
        tab === "Pending Review"
          ? "review"
          : tab === "Rejected"
          ? "refuesd"
          : "pass";
      this.queryData(_status);
    },
  },
  mounted() {
    this.queryData("review");
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
  display: flex;

  .article-list {
    flex: 3;
    margin-right: 20px;
  }

  .middle {
    flex: 1;
    margin-right: 20px;
  }
  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}
</style>
